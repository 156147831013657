import { Link } from "react-router-dom";
import style from "./style.css";
import "../../index.css";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import doc1 from "../../assets/Informacja_do_uzytkownik_Motohistorii.pdf";
import doc2 from "../../assets/Oswiadczenia_zgody_uzytkownik_Motohistorii.pdf";
import doc3 from "../../assets/REGULAMIN_19122024.pdf";
import {
  ErrorModal,
  LoadingModal,
  PickACarModal,
  RadialMenu,
  Regulations,
  SuccessModal,
} from "../../components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MainContent from "../MainContent";
import CookieBanner from "../../components/CookieBanner/CookieBanner";

export default function LandingPageLayout({ children }) {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <div id="rootLP">
          <SuccessModal />
          <ErrorModal />
          <LoadingModal />
          <RadialMenu />
          <PickACarModal />
          <Regulations />
          <CookieBanner />
          <nav>
            <Link to="/" className="nav_app_title">
              <span>moto</span>
              <span>Historia.pl</span>
            </Link>
            <div className="nav_actions_container">
              {/* <Link to="/login" className="nav_action bg-transparent">
                Zaloguj
              </Link>
              <Link to="/register" className="nav_action">
                Zarejestruj się
              </Link> */}
            </div>
          </nav>
          {children}
          {/* <MainContent /> */}
          <footer>
            <div>
              <Link to="/" className="nav_app_title">
                <span>moto</span>
                <span className="text-white">Historia.pl</span>
              </Link>
              <div id="socials">
                <a
                  href="https://www.facebook.com/profile.php?id=61551650467936"
                  target="_blank"
                >
                  <Facebook />
                </a>
                <a
                  href="https://www.instagram.com/motohistoria.pl"
                  target="_blank"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.youtube.com/@motohistoriapl"
                  target="_blank"
                >
                  <YouTube />
                </a>
              </div>
            </div>
            <div className="footer-links">
              <Link to="/about">O nas</Link>
              <Link to="/products">Produkty</Link>
              <Link to={doc1} rel="noreferrer" target="_blank">
                Informacja
              </Link>
              <Link to={doc2} rel="noreferrer" target="_blank">
                Oświadczenie zgody użytkownika
              </Link>
              <Link to={doc3} rel="noreferrer" target="_blank">
                Regulamin
              </Link>
              <Link to="/contact">Kontakt</Link>
            </div>
          </footer>
          <div
            style={{
              position: "fixed",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgb(143, 53, 68)",
              color: "white",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            <h3>Drodzy Użytkownicy!</h3>
            <p>
              Aplikacja w trakcie modernizacji. Już niedługo wersja Motohistoria
              v2.0 zmieniamy się dla Was! Za niedogodności przepraszamy!
            </p>
          </div>
        </div>
      </>
    </QueryClientProvider>
  );
}
